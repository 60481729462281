:root {
  --adm-radius-s: 0.04rem;
  --adm-radius-m: 0.08rem;
  --adm-radius-l: 0.12rem;
  --adm-font-size-1: 0.09rem;
  --adm-font-size-2: 0.1rem;
  --adm-font-size-3: 0.11rem;
  --adm-font-size-4: 0.12rem;
  --adm-font-size-5: 0.13rem;
  --adm-font-size-6: 0.14rem;
  --adm-font-size-7: 0.15rem;
  --adm-font-size-8: 0.16rem;
  --adm-font-size-9: 0.17rem;
  --adm-font-size-10: 0.18rem;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-yellow: #ff9f18;
  --adm-color-orange: #ff6430;
  --adm-color-wathet: #e7f1ff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-background: #ffffff;
  --adm-color-highlight: var(--adm-color-danger);
  --adm-color-white: #ffffff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-border-color: var(--adm-color-border);
}

html[data-prefers-color-scheme='dark'] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-yellow: #ffa930;
  --adm-color-orange: #e65a2b;
  --adm-color-wathet: #0d2543;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: #808080;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-color-background-body: var(--adm-color-background);
  --adm-border-color: var(--adm-color-border);
}

:root {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  background-color: var(--adm-color-background-body);
}

body {
  color: var(--adm-color-text);
  font-size: var(--adm-font-size-main);
  font-family: var(--adm-font-family);
}

a,
button {
  cursor: pointer;
}

a {
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}

a:active {
  opacity: 0.8;
}

.adm-plain-anchor {
  color: unset;
  transition: none;
}

.adm-plain-anchor:active {
  opacity: unset;
}

body.adm-overflow-hidden {
  overflow: hidden !important;
}

div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 0.02rem);
  width: 0;
  position: fixed;
  left: -100vw;
  top: -100vh;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}