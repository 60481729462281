.adm-list {
  --header-font-size: var(--adm-font-size-7);
  --prefix-width: 'auto';
  --prefix-padding-right: 0.12rem;
  --align-items: center;
  --active-background-color: var(--adm-color-border);
  --border-inner: solid 0.01rem var(--adm-color-border);
  --border-top: solid 0.01rem var(--adm-color-border);
  --border-bottom: solid 0.01rem var(--adm-color-border);
  --padding-left: 0.12rem;
  --padding-right: 0.12rem;
  --font-size: var(--adm-font-size-9);
  --extra-max-width: 70%;
}

.adm-list-header {
  color: var(--adm-color-weak);
  font-size: var(--header-font-size);
  padding: 0.08rem var(--padding-right) 0.08rem var(--padding-left);
}

.adm-list-body {
  background-color: var(--adm-color-background);
  overflow: hidden;
  font-size: var(--font-size);
}

.adm-list-body-inner {
  margin-top: -0.01rem;
}

.adm-list-default .adm-list-body {
  border-top: var(--border-top);
  border-bottom: var(--border-bottom);
}

.adm-list-card {
  margin: 0.12rem;
}

.adm-list-card .adm-list-body {
  border-radius: 0.08rem;
}

.adm-list-card .adm-list-header {
  padding-left: 0;
}

.adm-list-item {
  display: block;
  padding-left: var(--padding-left);
  position: relative;
  background-color: var(--adm-color-background);
  line-height: 1.5;
}

.adm-list-item-title,
.adm-list-item-description {
  color: var(--adm-color-weak);
  font-size: var(--adm-font-size-main);
}

.adm-list-item-content {
  display: flex;
  align-items: var(--align-items);
  justify-content: flex-start;
  border-top: var(--border-inner);
  padding-right: var(--padding-right);
}

.adm-list-item-content-prefix {
  width: var(--prefix-width);
  flex: none;
  padding-right: var(--prefix-padding-right);
}

.adm-list-item-content-main {
  flex: auto;
  padding: 0.12rem 0;
}

.adm-list-item-content-extra {
  flex: none;
  padding-left: 0.12rem;
  font-size: var(--adm-font-size-7);
  color: var(--adm-color-weak);
  max-width: var(--extra-max-width);
}

.adm-list-item-content-arrow {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: 0.04rem;
  color: var(--adm-color-light);
  font-size: 0.19rem;
}

.adm-list-item-disabled {
  cursor: not-allowed;
}

.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
  opacity: 0.4;
  pointer-events: none;
}

a.adm-list-item:active:not(.adm-list-item-disabled) {
  background-color: var(--active-background-color);
}

a.adm-list-item:active:not(.adm-list-item-disabled)::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  bottom: -0.01rem;
  left: 0;
  border-bottom: var(--border-inner);
}