.adm-toast-mask .adm-toast-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.adm-toast-mask .adm-toast-main {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  max-width: 2.04rem;
  max-height: 70%;
  overflow: auto;
  color: white;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.08rem;
  pointer-events: all;
  font-size: var(--adm-font-size-7);
  line-height: 1.5;
  box-sizing: border-box;
  text-align: initial;
}

.adm-toast-mask .adm-toast-main-text {
  padding: 0.12rem;
  min-width: 0;
}

.adm-toast-mask .adm-toast-main-icon {
  padding: 0.35rem 0.12rem;
  min-width: 1.5rem;
}

.adm-toast-mask .adm-toast-main-icon .adm-toast-icon {
  text-align: center;
  margin-bottom: 0.08rem;
  font-size: 0.36rem;
  line-height: 1;
}

.adm-toast-loading {
  --size: 0.48rem;
  margin: 0 auto 0.08rem;
}